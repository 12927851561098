import React from "react";

import { useNavigate } from "react-router-dom";

import { TbArrowBackUp } from "react-icons/tb";

const Formation = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <section className="flex mt-10">
      <div className="container mx-auto">
        {/* title */}
        <h1 className="text-[32px] lg:text-[69px] text-blue font-bold leading-tight mb-6">
          PRÉSENTATION DE NOTRE FORMATION
        </h1>
        <p className="max-w-[750px] leading-relaxed mb-20 lg:text-[24px]">
          Vous rêvez d'être votre propre patron ? Devenez un expert du vitrage
          automobile avec PACA Pare-Brise ! Lancez votre propre entreprise dans
          le domaine du vitrage automobile et de profiter de marges
          exceptionnelles avec PACA Pare-Brise, nous vous proposons une
          formation complète et personnalisée qui vous guidera pas à pas pour
          devenir un professionnel reconnu.
        </p>
        <h2 className="section-title lg:pt-16 mb-5 ">
          Notre formation comprend :
        </h2>

        <div className="mb-10">
          <h3 className="h3 mb-4 text-blue/70">
            Comment créer une micro-entreprise reconnue par les assurances
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Découvrez les étapes essentielles pour établir une entreprise
            approuvée par les compagnies d'assurance.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Comment générer plusieurs milliers d'euros en moins d'un mois
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Apprenez des stratégies éprouvées pour augmenter rapidement vos
            revenus.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Liste des fournisseurs de vitrage avec livraison en moins de 24
            heures
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Accédez à notre annuaire de fournisseurs fiables qui garantissent
            une livraison express.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Liste complète du matériel indispensable et des fournisseurs
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Obtenez tout le nécessaire pour vos interventions, avec les
            meilleurs fournisseurs du marché.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Réseau spécialisé dans la gestion des sinistres
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Nos partenaires s'occupent de tout l'administratif avec les
            assurances. Vous n'avez qu'à leur transmettre l'attestation
            d'assurance et le numéro de téléphone du client. Ils gèrent le reste
            : cession de créance, vérification et déclaration des bris de glace,
            facturation, suivi en temps réel de chaque dossier, et relances
            jusqu'au paiement.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Stratégies pour trouver un poseur local en vitrage
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Identifiez les experts de votre région qui peuvent vous accompagner
            lors de vos interventions et vous apprendre le métier tout en
            encaissant des factures.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Stratégies pour trouver des clients
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Découvrez les meilleures méthodes pour attirer et fidéliser votre
            clientèle.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Marketing digital et réseaux sociaux
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Apprenez à exploiter les outils numériques pour promouvoir votre
            activité.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Accompagnement et suivi personnalisé
          </h3>
          <p className="text-gray text-[18px] mb-8">
            Bénéficiez d'un soutien individualisé tout au long de votre
            parcours.
          </p>
        </div>

        <div>
          <h3 className="h3 mb-4 text-blue/70">
            Tous les bons plans et plus encore
          </h3>
          <p className="text-gray text-[18px] mb-20">
            Profitez de conseils exclusifs et d'astuces pour optimiser votre
            entreprise.
          </p>
        </div>

        <p className="max-w-[900px] leading-relaxed mb-8 lg:text-[22px]">
          De plus, vous accéderez à une formation théorique complète sur le
          remplacement de pare-brise, comprenant de nombreuses informations,
          conseils et astuces. Nous vous guidons étape par étape avec des vidéos
          détaillées et des commentaires pour chaque type de vitrage :
          <br />
          <br />
          Pare-brise sans capteur <br />
          Pare-brise avec capteur, caméra, etc. <br />
          Vitre arrière <br />
          Vitres latérales et custode <br />
          Toit panoramique
        </p>

        <p className="max-w-[900px] leading-relaxed mb-8 lg:text-[22px]">
          Automatisation et gestion simplifiée des dossiers bris de glace :{" "}
          <br /> La partie la plus complexe, la gestion des dossiers clients,
          est prise en charge par notre réseau expérimenté et notre équipe
          qualifiée. Nous vous accompagnons depuis la déclaration de sinistre
          jusqu'à la facturation et le paiement, y compris la gestion des
          retards de paiement par les assurances et un suivi rigoureux des
          dossiers.
        </p>
        <p className="max-w-[900px] leading-relaxed mb-8 lg:text-[22px]">
          Vous n'aurez plus à gérer la paperasse ni à embaucher du personnel
          administratif. Une équipe dédiée est essentielle pour atteindre un
          volume élevé de dossiers.
        </p>

        <p className="max-w-[900px] leading-relaxed mb-8 lg:text-[22px]">
          Conseil : Ne prenez aucun associé. Avec notre soutien, vous n'en aurez
          pas besoin. Vous aurez accès à notre groupe privé à vie.
        </p>

        <div className="flex flex-col lg:flex-row justify-between mb-10">
          <p className="max-w-[900px] leading-relaxed mb-8 lg:text-[22px]">
            Prix de la formation : 1499 € au lieu de 3000 € avec le code promo
            "parebrise50". <br />
            La formation serra rentabilisée au bout 2 pare-brise remplacé !!!!
          </p>
          <button className="btn px-8 justify-center mb-10">
            <a
              href="https://buy.stripe.com/fZeeW0dmF2mKeEUaEE"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acheter Notre Formation
            </a>
          </button>
        </div>

        {/* Button Back */}
        <div className="fixed bottom-5 left-0 right-0 flex justify-center w-full z-10">
          <button
            onClick={handleGoBack}
            className="btn justify-center w-[250px] h-[40px] text-base gap-x-4"
          >
            <TbArrowBackUp />
            Retourner vers le site
          </button>
        </div>
      </div>
    </section>
  );
};

export default Formation;
